import React from 'react';

export default {
  routes: [
    {
      exact: true,
      path: '/index',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/index'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/index'),
      ),
    },
    {
      exact: true,
      path: '/redirect',
      isDynamic: true,
      component: React.lazy(
        () => import(/* webpackChunkName: "Redirect",webpackPrefetch: true */ '@/pages/Redirect'),
      ),
    },
    {
      exact: true,
      path: '/authorization',
      isDynamic: true,
      component: React.lazy(
        () => import(/* webpackChunkName: "Redirect",webpackPrefetch: true */ '@/pages/Authorization'),
      ),
    },
    {
      exact: true,
      path: '/o2n',
      isDynamic: true,
      component: React.lazy(
        () => import(/* webpackChunkName: "Redirect",webpackPrefetch: true */ '@/pages/o2n'),
      ),
    },
    {
      exact: true,
      path: '/aboutUs',
      isDynamic: true,
      component: React.lazy(
        () => import(/* webpackChunkName: "page1",webpackPrefetch: true */ '@/pages/pc/about-us'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/about-us'),
      ),
    },
    {
      name: '国诚资讯',
      exact: true,
      path: '/gcInfo',
      isDynamic: true,
      component: React.lazy(
        () => import(/* webpackChunkName: "page1",webpackPrefetch: true */ '@/pages/pc/gc-info'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/gc-info'),
      ),
    },
    {
      name: '国诚资讯详情',
      exact: true,
      path: '/gcInfo/detail',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/gc-info/detail'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/gc-info/detail'),
      ),
    },
    {
      exact: true,
      path: '/product',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/product'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/product'),
      ),
    },
    {
      name: '安全合规',
      exact: true,
      path: '/compliance/:activeMenu',
      isDynamic: true,
      component: React.lazy(
        () => import(/* webpackChunkName: "page1",webpackPrefetch: true */ '@/pages/pc/compliance'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/compliance'),
      ),
    },
    {
      name: '联系我们',
      exact: true,
      path: '/contactUs',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/contact-us'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/contact-us'),
      ),
    },
    {
      name: '安全合规详情',
      exact: true,
      path: '/compliance/:activeMenu/detail',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/compliance/detail'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/compliance/detail'),
      ),
    },
    {
      name: '观点分析',
      exact: true,
      path: '/information',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/information'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/information'),
      ),
    },
    {
      name: '国诚智投',
      exact: true,
      path: '/investment',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/investment'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/investment'),
      ),
    },
    {
      name: '下载提示页',
      exact: true,
      path: '/tip-page',
      isDynamic: true,
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/investment/tip-page'),
      ),
    },
    {
      name: '观点分析详情',
      exact: true,
      path: '/information/detail',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/information/detail'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/information/detail'),
      ),
    },
    {
      name: '观点分析每日股评详情',
      exact: true,
      path: '/information/article-detail',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/information/article-detail'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/information/article-detail'),
      ),
    },
    {
      name: '隐私协议',
      exact: true,
      path: '/privacyProtocol',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/privacyProtocol'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/pc/privacyProtocol'),
      ),
    },
    {
      name: '用户政策',
      exact: true,
      path: '/userPolicy',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/userPolicy'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/pc/userPolicy'),
      ),
    },
    {
      name: '投资圈介绍',
      exact: true,
      path: '/introduce',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/mobile/investmentIntroduce'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/investmentIntroduce'),
      ),
    },
    {
      name: '重磅升级',
      exact: true,
      path: '/upgrade',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/mobile/heavyUpgrade'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/heavyUpgrade'),
      ),
    },
    {
      name: '从业人员公示',
      exact: true,
      path: '/showPractitioner',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/showPractitioner'),
      ),
      // mobileComponent: React.lazy(
      //     ()=> import('@/pages/pc/userPolicy'),
      // ),
    },
    {
      name: '下载页',
      exact: true,
      path: '/gczt_download',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/download/robo'),
      ),
    },
    {
      name: '决策家APP下载页',
      exact: true,
      path: '/download/jcj-app',
      isDynamic: true,
      mobileComponent: React.lazy(
          () => import('@/pages/mobile/download/jcj-app'),
      ),
    },
    {
      name: '公司介绍页',
      exact: true,
      path: '/company_introduction',
      isDynamic: true,
      component: React.lazy(
        () => import('@/pages/pc/companyIntroduce'),
      ),
      mobileComponent: React.lazy(
        () => import('@/pages/mobile/companyIntroduce'),
      ),
    },
    {
      name: '决策家，您的炒股好帮手',
      exact: true,
      path: '/download/jcj-app/bd',
      isDynamic: true,
      mobileComponent: React.lazy(
          () => import('@/pages/mobile/download/jcj-app/bd'),
      ),
    }
  ],
};
